import React, { useEffect, createRef } from "react";
import { motion } from "framer-motion";
import IconMenuInstagram from "../../images/icon-instagram.svg";
import IconMenuLinkedin from "../../images/icon-linkedin.svg";
import IconMenuX from "../../images/icon-X.svg";
import IconMenuFacebook from "../../images/icon-facebook.svg";
import IconMenuVimeo from "../../images/icon-vimeo.svg";
import PrismicLink from "../prismicLink";
import { freezeBody, unfreezeBody } from "../../utils/utils";

const menuRef = createRef();

const handleKeyDown = (e) => {
  const focusableModalElements = menuRef.current.querySelectorAll(
    'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
  );
  const firstFocusableElement = focusableModalElements[0];
  const lastFocusableElement =
    focusableModalElements[focusableModalElements.length - 1];
  let isTabPressed = e.key === "Tab" || e.keyCode === 9;

  if (!isTabPressed) {
    return;
  }

  if (e.shiftKey) {
    // if shift key pressed for shift + tab combination
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus(); // add focus for the last focusable element
      e.preventDefault();
    }
  } else {
    // if tab key is pressed
    if (document.activeElement === lastFocusableElement) {
      // if focused has reached to last focusable element then focus first focusable element after pressing tab
      firstFocusableElement.focus(); // add focus for the first focusable element
      e.preventDefault();
    }
  }
};

const sidebar = {
  open: ({ height = 1000, leftPosition }) => ({
    clipPath: `circle(${height * 2 + 200}px at ${leftPosition - 20}px 50px)`,
    display: "flex",
    transition: {
      duration: 0.8,
    },
  }),
  closed: ({ leftPosition }) => ({
    clipPath: `circle(0px at ${leftPosition - 20}px 50px)`,
    transitionEnd: { display: "none" },
    transition: {
      delay: 0.2,
      duration: 0.4,
      ease: "easeOut",
    },
  }),
};

const HamburgerIcon = ({ leftPosition, onClick, focusable }) => (
  <button
    className="menu-btn"
    style={{ left: leftPosition - 50 }}
    onClick={onClick}
    name="menu-btn"
    tabIndex={focusable ? "0" : "-1"}
  >
    <motion.span
      inherit={true}
      variants={{
        closed: {
          rotate: 0,
          y: 0,
          backgroundColor: "black",
        },
        open: {
          rotate: -45,
          y: 12,
          backgroundColor: "white",
        },
        hidden: {
          scaleX: 0,
        },
        visible: {
          scaleX: 1,
        },
      }}
    />
    <motion.span
      variants={{
        closed: {
          opacity: 1,
        },
        open: {
          opacity: 0,
        },
        hidden: {
          scaleX: 0,
        },
        visible: {
          scaleX: 1,
          transition: { delay: 0.2 },
        },
      }}
    />
    <motion.span
      variants={{
        closed: {
          rotate: 0,
          y: 0,
          backgroundColor: "black",
        },
        open: {
          rotate: 45,
          y: -13,
          backgroundColor: "white",
        },
        hidden: {
          scaleX: 0,
        },
        visible: {
          scaleX: 1,
          transition: { delay: 0.4 },
        },
      }}
    />
    <span className="menu-text">menu</span>
  </button>
);

const menuItemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

function ExpandableMenu({
  menu = [],
  leftPosition,
  node,
  isExpandableOpen,
  toggleExpandableMenu,
  setIsAnimationCompleted,
  isVisible,
}) {
  const linkX = node?.social_twitter;
  const linkLinkedin = node?.social_linkedin;
  const linkInstagram = node?.social_instagram;
  const linkFacebook = node?.social_facebook;
  const linkVimeo = node?.social_vimeo;

  useEffect(() => {
    if (isExpandableOpen) freezeBody();
    else unfreezeBody();
  }, [isExpandableOpen]);

  return (
    <motion.div
      className="header-expand"
      initial={false}
      inherit={true}
      ref={menuRef}
      animate={isExpandableOpen ? "open" : "closed"}
      onAnimationComplete={() => {
        setIsAnimationCompleted(!isExpandableOpen);
      }}
      onAnimationStart={() => {
        setIsAnimationCompleted(false);
      }}
    >
      <HamburgerIcon
        leftPosition={leftPosition}
        onClick={toggleExpandableMenu}
        focusable={isVisible}
      />
      <motion.div
        className="background"
        variants={sidebar}
        custom={{ leftPosition }}
      >
        <div className="menu">
          <motion.ul
            variants={{
              open: {
                transition: { staggerChildren: 0.07, delayChildren: 0.2 },
              },
              closed: {
                transition: { staggerChildren: 0.05, staggerDirection: -1 },
              },
            }}
          >
            {menu.map((menuItem, idx) => (
              <motion.li
                variants={menuItemVariants}
                key={idx}
                className="menu__item"
              >
                <PrismicLink
                  link={menuItem.main_navigation_link}
                  onClick={toggleExpandableMenu}
                  onKeyDown={handleKeyDown}
                >
                  {menuItem?.main_navigation_name?.text}
                </PrismicLink>
              </motion.li>
            ))}
            {/* <motion.div
              variants={menuItemVariants}
              className="menu__logo-modex"
            >
              <Link to="/blog/" onClick={toggleExpandableMenu} onKeyDown={handleKeyDown}>
                MODE<span className="slash">/</span>X
              </Link>
            </motion.div> */}
            <motion.div variants={menuItemVariants} className="menu__social">
              {linkLinkedin && (
                <PrismicLink link={linkLinkedin} onKeyDown={handleKeyDown}>
                  <IconMenuLinkedin />
                </PrismicLink>
              )}
              {linkFacebook && (
                <PrismicLink link={linkFacebook} onKeyDown={handleKeyDown}>
                  <IconMenuFacebook />
                </PrismicLink>
              )}
              {linkInstagram && (
                <PrismicLink link={linkInstagram} onKeyDown={handleKeyDown}>
                  <IconMenuInstagram />
                </PrismicLink>
              )}
              {linkX && (
                <PrismicLink link={linkX} onKeyDown={handleKeyDown}>
                  <IconMenuX />
                </PrismicLink>
              )}
              {linkVimeo && (
                <PrismicLink link={linkVimeo} onKeyDown={handleKeyDown}>
                  <IconMenuVimeo />
                </PrismicLink>
              )}
            </motion.div>
          </motion.ul>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default ExpandableMenu;
