class Utils {
  static IS_BROWSER = typeof window !== "undefined";

  static getFluidImg = (image, size) => {
    const imageUrl = new URL(image.url);
    const params = new URLSearchParams(imageUrl.search);
    params.set("width", size);
    imageUrl.search = params.toString();
    return {
      aspectRatio: image.dimensions.width / image.dimensions.height,
      src: imageUrl.toString(),
      srcSet: `${imageUrl.toString()} ${size}w`,
      fluid: `(max-width: ${size}px) 100vw, ${size}px`,
    };
  };

  static freezeBody = () => {
    document.body.style.overflowY = "hidden";
    document.body.classList.add("hamburger-menu-opened");
  };

  static unfreezeBody = () => {
    document.body.style.overflowY = "";
    document.body.classList.remove("hamburger-menu-opened");
  };

  static isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  static buildURLParams = (key, value) => {
    if (!Utils.IS_BROWSER) return null;
    let url = new URL(window.document.location);
    let params = new URLSearchParams(url.search.slice(1));

    if (!value || Utils.isEmpty(value)) {
      params.delete(key);
    } else {
      const parsedValue =
        typeof value === "string" ? value : JSON.stringify(value);
      if (params.has(key)) {
        params.set(key, parsedValue);
      } else {
        params.append(key, parsedValue);
      }
    }

    return params;
  };

  static insertUrlParam = (
    key,
    value,
    { refresh, push } = { refresh: false, push: false }
  ) => {
    if (!Utils.IS_BROWSER) return null;
    if (window.history.pushState) {
      const params = Utils.buildURLParams(key, value);
      const { protocol, host, pathname } = window.location;
      const newUrl = `${protocol}//${host}${pathname}?${params.toString()}`;
      if (refresh) {
        window.location.href = newUrl;
      } else {
        if (push) {
          window.history.pushState({ path: newUrl }, "", newUrl);
        } else {
          window.history.replaceState({ path: newUrl }, "", newUrl);
        }
      }
    }
  };

  static getUrlParams = (key) => {
    if (!Utils.IS_BROWSER) return null;
    const urlSearch = window.location.search.replace("?", "");
    if (urlSearch) {
      const params = Object.fromEntries(new URLSearchParams(urlSearch));
      if (params && params[key]) {
        try {
          return JSON.parse(params[key]);
        } catch (err) {
          return params[key];
        }
      }
    }
  };

  static stringToHTML = (str) => {
    if (!Utils.IS_BROWSER) return null;
    var dom = window.document.createElement("div");
    dom.innerHTML = str;
    return dom;
  };

  static slugify = (slug) => {
    return slug
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
  };

  static getTagType(type) {
    const tagMap = {
      heading1: "h1",
      heading2: "h2",
      heading3: "h3",
      heading4: "h4",
      heading5: "h5",
      heading6: "h6",
      paragraph: "p",
      bulletedList: "ul",
      numberedList: "ol",
      code: "code",
      embed: "embed",
      image: "img",
    };

    return tagMap[type];
  }

  static getFirstArrayItem = (arr) => {
    return arr && arr.length ? arr[0] : undefined;
  };
}

module.exports = Utils;
