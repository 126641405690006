import * as React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import Layout from "./src/components/layout";
import linkResolver from "./src/utils/linkResolver";

const transitionDelay = 25;

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO,
        linkResolver,
        // componentResolver: componentResolverFromMap({
        //   page: Page,
        //   team_member: TeamMember,
        // }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);

export const wrapPageElement = ({ element, props }) => {
  if (props.path.startsWith("/proposal")) {
    return element;
  }
  return <Layout {...props}>{element}</Layout>;
};

export const shouldUpdateScroll = (props) => {
  const {
    routerProps: { location },
    getSavedScrollPosition,
  } = props;

  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }
  return false;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation !== null) {
    const firstFocus = document.querySelector("#gatsby-focus-wrapper");
    if (firstFocus) {
      firstFocus.focus();
    }
  }
};
