import React from "react";
import { graphql, Link } from "gatsby";
import linkResolver from "../utils/linkResolver";

export const fragments = graphql`
  fragment PrismicLink on PrismicLink {
    id
    link_type
    uid
    type
    slug
    url
    target
  }
`;

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const PrismicLink = ({
  children,
  link: to,
  activeClassName,
  partiallyActive,
  ...other
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(linkResolver(to));

  const props = {
    ...other,
    // Conditionally add the `target` property if `to.target` exists
    ...(to?.target && { target: to.target }),
  };

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <Link
        to={linkResolver(to)}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...props}
      >
        {children}
      </Link>
    );
  }
  return (
    <a href={to?.url} {...props}>
      {children}
    </a>
  );
};

export default PrismicLink;
